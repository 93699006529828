import {MultiSelectMessage, HandlePostMessage} from '../types';
import plural from '../utils/plural';

export default function postMultiSelectMessage(
  accessory: MultiSelectMessage,
  value: string[] | string,
  onPostMessage: HandlePostMessage,
  setValue: (value: string[] | string) => unknown,
) {
  const listFormatter = new Intl.ListFormat(undefined, {
    style: 'long',
    type: 'conjunction',
  });
  if (Array.isArray(value)) {
    // if we get an array, we want to match array values to
    // their displayed labels here, so we do this filter->map
    // that associates values with payloads and then map those
    // onto titles in the accessory buttons.
    const matchedValues = accessory.buttons.filter(({payload}) =>
      value.includes(payload),
    );
    const labels = matchedValues.map(({title}) => title);

    if (labels.length === value.length) {
      onPostMessage(listFormatter.format(labels), value);
    } else {
      // it's possible that we tried to match values to the accessory, but that the
      // accessory doesn't have payload/title pairs that match the value, in this
      // case we should just format the array and send it down as it was originally
      onPostMessage(listFormatter.format(value), value);
    }
    setValue([]);
  } else if (accessory.metadata.response_type !== 'string') {
    // this is a preset question, so we just pass along the value directly
    onPostMessage(value, value);
    setValue('');
    return;
  } else {
    // find any strings that *exactly match* labels in the multiselect
    // and return an array of those titles' associated payloads
    const matchedValues = accessory.buttons
      .filter(({title}) =>
        // value is a string, does it include the button's title string?
        value.toLowerCase().includes(title.toLowerCase()),
      )
      .map(({payload}) => payload);

    onPostMessage(value, matchedValues);
    setValue('');
  }
}
