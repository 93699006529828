import React from 'react';
import * as ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import './index.css';

import {ChatId, InboundDisplayMode, BrandingSettings} from './types';
import App from './App';
import * as serviceWorker from './serviceWorker';
import api, {senseApi} from './utils/bot-api';
import {useResourceApi} from './hooks/useApi';
import {ErrorPage} from './Loading';

import {embedReciteMe} from './utils/reciteme';
import appCss from './App.module.css';

import WidgetButton from './WidgetButton';

Sentry.init({
  dsn:
    'https://0cd70e75df76f32876cbd0dbcbc4fcae@o31059.ingest.sentry.io/4506667495260160',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/.*\/\.sensehqchat\.com\.*/,
        /^https:\/\/.*\/\.sensehq\.com\/api\/v1\/.*/,
      ],
    }),
    // marcos: not using this right now
    // new Sentry.Replay({
    //   maskAllText: false,
    //   blockAllMedia: false,
    // }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 10% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootEl = document.getElementById('root');
if (!rootEl) {
  throw new Error('cannot render react without an app root');
}
const root = ReactDOM.createRoot(rootEl);

function nonSenseParams(params: URLSearchParams): {[key: string]: string} {
  const customParamKeys = ['job_id', 'job_title', 'application_source'];

  const filtered = [...params.entries()].filter(([key]) =>
    customParamKeys.includes(key),
  );
  const mapped = filtered.map(([key, val]) => [`custom/${key}`, val]);
  return Object.fromEntries(mapped);
}

function initStandalone(location: Location) {
  const searchParams = new URLSearchParams(location.search);

  const flowId = searchParams.get('flowId');
  const isSms = searchParams.get('isSms');
  const isPreview = searchParams.get('isPreview') === 'true' ?? false;

  // NOTE (kyle): if a `flowId` is specified in the search params,
  // we ignore any pathnames.
  let chatId: ChatId;
  if (flowId) {
    // NOTE (kyle): this should only happen once.
    api.setBasePath('/api/nlu');
    chatId = {value: flowId, type: 'flow_id'};
  } else {
    const chatCode = location.pathname.replace(/^\//, '');
    chatId = {value: chatCode, type: 'chat_code'};
  }

  const customParams = nonSenseParams(searchParams);

  root.render(
    <App
      chatId={chatId}
      isSms={Boolean(isSms) && isSms !== 'false'}
      isPreview={isPreview}
      customParams={customParams}
    />,
  );
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}

function initInboundBot(location: Location) {
  if (window.parent === window) {
    root.render(
      <div>this inbound widget is supposed to be embedded by a script</div>,
    );

    return;
  }

  root.render(<div>waiting to load chatbot...</div>);

  // once we render this placeholder ui, we send the load message
  // back up to the parent window
  window.parent.postMessage(
    {
      type: 'load',
    },
    '*',
  );

  let setData = (any: any) => console.warn('waiting for inbound data');

  const Shell = () => {
    const [data, setAppData] = React.useState<null | {
      flow_id: string;
      customParams: {[key: string]: string};
      side: 'left' | 'right';
      is_mobile: boolean;
      mode: InboundDisplayMode;
      brand_settings: BrandingSettings;
    }>(null);

    console.log('chatbot shell rendered');
    setData = setAppData;

    const handleChangeDisplayMode = (displayMode: InboundDisplayMode) => {
      window.parent.postMessage(
        {
          type: 'changeDisplayMode',
          payload: displayMode,
        },
        '*',
      );
    };
    const handleToggle = () => {
      window.parent.postMessage({type: 'toggleDisplayMode'}, '*');
    };

    if (data == null) {
      return null;
    }

    return (
      <App
        chatId={{type: 'flow_id', value: data.flow_id}}
        isSms={false}
        handleLogoClick={handleToggle}
        flowBrandSettings={data.brand_settings}
        handleChangeDisplayMode={handleChangeDisplayMode}
        inboundDisplayMode={data.mode}
        isMobile={data.is_mobile}
        side={data.side}
        customParams={data.customParams}
      />
    );
  };

  const renderApp = () => {
    root.render(<Shell />);
  };

  window.addEventListener('message', event => {
    if (event.source !== window.parent) {
      return;
    }

    const {data} = event;

    console.log('[sense chat] received chatbot data from host webpage', data);

    if (data.reciteMeApiKey != null) {
      console.log('[sense chat] initializing reciteme integration', data);
      embedReciteMe(data.reciteMeApiKey);
    }

    if (data.type === 'start-flow') {
      // // previously the widget resized itself, but not anymore, we do that
      // // from the snippet once we initiate this because the snippet knows
      // // the requested dimensions/size/etc
      // // could still do this in the future though...
      // data.window.parent.postMessage(
      //   {
      //     type: 'setDisplayMode',
      //     payload: data.display_mode,
      //   },
      //   '*',
      // );

      console.log('[sense chat] rendering app and starting chatbot flow', data);

      setData(data);
    }
    if (data.type === 'setDisplayMode') {
      // @ts-ignore
      setData(prevData => ({...prevData, ...data}));
    }
  });

  renderApp();
}

if (window.location.pathname === '/inbound-button') {
  const handleToggle = (opened: boolean) => {
    window.parent.postMessage({type: 'toggleDisplayMode'}, '*');
  };
  const renderButton = (opened: boolean, flowBrandSettings: BrandingSettings) =>
    root.render(
      <WidgetButton
        opened={opened}
        onClick={(opened: boolean) => handleToggle(opened)}
        flowBrandSettings={flowBrandSettings}
      />,
    );

  renderButton(false, {});
  window.addEventListener('message', event => {
    if (event.source !== window.parent) {
      return;
    }
    const {data} = event;
    // commenting this out because it's usually more noisy
    // console.log('rendering widget button with data', data);
    if (data.type === 'setDisplayMode') {
      renderButton(data.mode !== 'min', data.brand_settings);
    }
  });
} else if (window.location.pathname === '/inbound') {
  initInboundBot(window.location);
} else if (window.location.pathname.startsWith('/s/')) {
  const suffix = window.location.pathname.replace(new RegExp('^/s'), '');

  senseApi
    .post(`/chatbot/flow/by-url-suffix`, {suffix})
    .then(flowResource => {
      const customParams = getCustomParams(
        flowResource.sourcing_config?.application_bot_external_map ?? {},
      );

      root.render(
        <App
          chatId={{type: 'flow_id', value: flowResource?.id ?? undefined}}
          isSms={false}
          customParams={customParams}
        />,
      );
    })
    .catch(err => {
      root.render(
        <App
          chatId={{type: 'flow_id', value: undefined}}
          isSms={false}
          error={err}
        />,
      );
    });
} else if (window.location.pathname.startsWith('/source/')) {
  const parts = window.location.pathname.split('/');
  if (parts.length === 3) {
    root.render(
      <App chatId={{type: 'flow_id', value: parts[2]}} isSms={false} />,
    );
  }
} else {
  initStandalone(window.location);
}

/**
 * because params are liable to be found in either the search params or as the values
 * of dom attributes, we assume they're strings (they are passed through to the bot)
 */
type ParamValueMapping = {[customerVariableName: string]: string};

/**
 * this mapping is always external variable name -> custom variable name and we send it
 * down in the sourcing flow dict.
 */
type FlowParamMapping = {[externalVariableName: string]: string};

/**
 * This mapping has the same external variable keys, but the values are the detected
 * values on the page. This CustomParamMapping is what gets sent down to the bot
 */
type CustomParamMapping = FlowParamMapping;

function getMatchingQueryParams(names: string[]): {[key: string]: string} {
  /**
   * given a list of names, find matching values in the host page's url search parameters
   *
   * @returns {[key: string]: string} - an object keyed by the names passed in
   */
  const params = new URL(window.location.href).searchParams;
  const vals: Array<[string, string]> = names
    .map(name => {
      if (params.has(name)) {
        const val = params.get(name);
        if (name != null && val != null) {
          return [name, val];
        }
      }
      return null;
    })
    .filter(pair => Array.isArray(pair)) as [string, string][];
  if (vals.length > 0) {
    return Object.fromEntries(vals);
  }
  return {};
}

function mapFlowParams(
  flowParamMapping: FlowParamMapping,
  foundParams: ParamValueMapping,
): CustomParamMapping | undefined {
  const entries = Object.entries(flowParamMapping)
    .map(([extKey, customKey]) => {
      if (foundParams[customKey] != null) {
        return [extKey, foundParams[customKey]];
      } else {
        return null;
      }
    })
    .filter(entry => Array.isArray(entry)) as [string, string][];
  if (entries.length > 0) {
    return Object.fromEntries(entries);
  }
  return undefined;
}

function getCustomParams(
  flowParamMapping: FlowParamMapping,
): CustomParamMapping | undefined {
  const paramNames: string[] = Object.values(flowParamMapping);
  const matchedParams = getMatchingQueryParams(paramNames);
  return mapFlowParams(flowParamMapping, matchedParams);
}
