import {Api} from './api';

const {location} = window;

function senseApiOriginFromLocation(origin: string): string {
  const hostname = new URL(origin).hostname;
  let [agency, instance, ...rest] = hostname.split('.');

  // i.e. for  'agency.sensehqchat.com' instance is just our domain
  if (instance === 'sensehqchat' && rest.length === 1) {
    return `https://${agency}.sensehq.com`;
  }

  // all that follows is staging / dev logic

  // for default proxying, we just want to attempt using the local proxy
  if (hostname === 'localhost') {
    return origin;
  }

  // stage-1 has a distinct mapping
  if (instance === 'stage-1') {
    return `https://${agency}.stage-chatbot.sensehq.co`;
  }

  // match all other stage-xx domains
  const stageRe = new RegExp(/stage-(\d+)/);
  const stageNo = instance.match(stageRe);
  if (stageNo) {
    return `https://${agency}.stage-chatbot-${stageNo[1]}.sensehq.co`;
  }

  // Handling Pre Prod
  if(origin.includes('sandbox-preprod.sensehq.com')){
    return `https://${agency}.sandbox-preprod.sensehq.com`
  }
  
  // sort of a last ditch effort here
  return 'https://multientity.sensehq.com';
}

// The default api's basepath is updated by app/index.tsx
// This is the default value in prod, but it can be changed
// by calling .setBasePath()
export const chatbotApi = new Api(`${location.origin}/api/nlu`);
export default chatbotApi;

// this api represents non-nlu apis that run on chatbot service
// i.e. file upload
export const genericBotApi = new Api(`${location.origin}/api`);

// this api represents apis hosted on sensehq.com so the base path
// is almost always separate from the location, but usually has the
// same agency slug. We use
export const senseApi = new Api(
  `${senseApiOriginFromLocation(location.origin)}/api/v1`,
);

export type ChatbotApiErrorResponse = {
  errors: string[];
  is_internal: boolean;
  status: number;
};
