import * as React from 'react';
import marked from 'marked';

import {addLinks, createHtmlLink} from './lib/Linkify';

export default function MarkdownView({
  children,
  tagName = 'div',
  ...props
}: React.HTMLAttributes<HTMLElement> & {
  children: string;
  tagName?: keyof React.ReactHTML;
}) {
  return React.createElement(tagName, {
    ...props,
    dangerouslySetInnerHTML: {
      __html: marked(
        addLinks(children, match => {
          if (children[match.index - 1] !== '(') {
            return createHtmlLink(match);
          }
        }).join(''),
      ),
    },
  });
}

// NOTE (kyle): we want to render all chatbot markdow links with about=_blank
class LinkRenderer extends marked.Renderer {
  link(href: string | null, title: string | null, text: string): string {
    const titleAttribute = title ? ` title="${title}"` : '';
    return `<a href="${href}"${titleAttribute} target="_blank" rel="noopener nofollow">${text}</a>`;
  }
}
marked.setOptions({
  renderer: new LinkRenderer(),
});
