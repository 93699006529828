// @flow

import * as React from 'react';

import classify from '../../utils/classify';

import {ReactComponent as CheckedBox} from '../../../images/checked-box.svg';
import {ReactComponent as UncheckedBox} from '../../../images/unchecked-box.svg';

import Unstyled from './unstyled';

import css from './button.module.css';

type Props = {
  type?: 'default' | 'primary',
  icon?: React.Node,
  children?: React.Node,
  disabled?: boolean,
  selected?: boolean,
  style: {backgroundColor?: string, color?: string},
  onKeyPress?: (SyntheticKeyboardEvent<HTMLElement>) => mixed,
  onClick?: (SyntheticEvent<HTMLElement>) => mixed,
  className?: string,
};

function CheckboxButton(
  {type, icon, disabled, selected, style, className, ...props}: Props,
  ref: React.Ref<'div'>,
) {
  const finalStyle = selected
    ? style
    : {
        backgroundColor: style.color,
        color: style.backgroundColor,
      };

  return (
    <Unstyled
      className={classify(
        selected ? css.buttonChecked : css.buttonUnchecked,
        {
          [css.buttonDisabled]: disabled,
        },
        className,
      )}
      style={finalStyle}
      ref={ref}
      disabled={disabled}
      {...props}
    >
      <div className={css.icon}>
        {selected ? (
          <CheckedBox fill={style.color} stroke={style.backgroundColor} />
        ) : (
          <UncheckedBox fill={style.color} stroke={style.backgroundColor} />
        )}
      </div>
      <span className={css.children}>{props.children}</span>
    </Unstyled>
  );
}

export default React.forwardRef<Props, HTMLDivElement>(CheckboxButton);
