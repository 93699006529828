import * as React from 'react';

import last from 'lodash/last';

import classify from './utils/classify';

import {
  ApiMessage,
  ButtonData,
  AccessoryButtonData,
  Message,
  ChatbotDispatch,
  JobMatchAccessoryData,
  AccessoryData,
  AccessoryJobData,
  JobMatchMessage,
  HandlePostMessage,
} from './types';

import {useBranding} from './lib/Branding';

// @ts-ignore
import Button from './@spaced-out/components/button';

import {ReactComponent as MapIcon} from './images/map-icon.svg';
import {ReactComponent as BuildingIcon} from './images/building.svg';
import {ReactComponent as CloseIcon} from './images/close.svg';

import uiCss from './styles/ui.module.css';
import css from './Jobs.module.css';

export type ButtonStyle = {backgroundColor?: string; color?: string};

export default function Jobs({
  accessory,
  dispatch,
  setShowMatchDetails,
}: {
  accessory: JobMatchMessage;
  dispatch: ChatbotDispatch;
  setShowMatchDetails: (arg: boolean) => void;
}) {
  const matches = accessory.payload;
  const [selectedJob, setSelectedJob] = React.useState(matches?.[0] ?? null);
  const [showJobDetails, setShowJobDetails] = React.useState(false);

  const handleHideJobsView = () => setShowMatchDetails(false);

  return (
    <>
      <div className={css.desktopContainer}>
        <div className={css.header} onClick={handleHideJobsView}>
          Back to chat
        </div>
        <div className={css.jobsContainer}>
          <div className={css.jobList}>
            <div className={css.matchingJobCount}>{`${matches.length ||
              ''} matching jobs`}</div>

            <JobList
              isMobile={false}
              matches={matches}
              setSelectedJob={setSelectedJob}
              setShowJobDetails={setShowJobDetails}
            />
          </div>
          <JobDetails isMobile={false} selectedJob={selectedJob} />
        </div>
      </div>

      <div className={css.mobileContainer}>
        <div className={css.mobileContent}>
          <div className={css.mobileTopRow}>
            <CloseIcon onClick={handleHideJobsView} />
          </div>
          {!showJobDetails && (
            <>
              <div
                className={css.matchingJobCount}
              >{`${matches?.length} matching jobs`}</div>
              <JobList
                isMobile
                matches={matches}
                setSelectedJob={setSelectedJob}
                setShowJobDetails={setShowJobDetails}
              />
            </>
          )}
          {showJobDetails && <JobDetails isMobile selectedJob={selectedJob} />}
        </div>
      </div>
    </>
  );
}

const JobList = ({
  isMobile,
  matches,
  setSelectedJob,
  setShowJobDetails,
}: {
  isMobile: boolean;
  matches?: AccessoryJobData[];
  setSelectedJob: (job: AccessoryJobData) => void;
  setShowJobDetails: (arg: boolean) => void;
}) => (
  <>
    {matches &&
      matches.map(
        (
          {
            title,
            location,
            url,
          }: {title: string; location: string; url: string},
          index: number,
        ) => (
          <div
            aria-label={`Detail for ${title}`}
            className={css.jobSummary}
            onClick={() => {
              setSelectedJob(matches[index]);
              if (isMobile) {
                setShowJobDetails(true);
              }
            }}
          >
            <div className={css.jobTitle}>{title}</div>

            <div>{location}</div>
          </div>
        ),
      )}
  </>
);

const JobDetails = ({
  isMobile,
  selectedJob,
}: {
  isMobile: boolean;
  selectedJob: AccessoryJobData | null;
}) => {
  const brandingSettings = useBranding();

  return (
    <div className={css.jobDetailContainer}>
      <div className={css.jobDetail}>
        <div className={css.jobDetailTitle}>{selectedJob?.title}</div>
        <div className={css.jobDetailSubtitle}>
          <MapIcon />
          {selectedJob?.location}
        </div>
        <div
          className={css.jobDetailDescription}
          dangerouslySetInnerHTML={{
            __html: selectedJob?.description ?? '',
          }}
          style={{display: 'unset'}}
        />
      </div>
      <div className={css.applyNow}>
        {selectedJob?.url && (
          <a href={selectedJob?.url} target="_blank" rel="noreferrer">
            <Button type="primary" rel="noopener">
              {brandingSettings.chatbot_job_match_cta_label ??
                'See more and apply now'}
            </Button>
          </a>
        )}
      </div>
    </div>
  );
};
