import * as React from 'react';

import * as breakpoints from '../breakpoints';

type ResponsiveContextShape = {
  innerWidth: number; // "how wide is the chatbot"
  outerWidth: number; // "how wide is the chatbot's host window"
  context: 'mobile' | 'desktop' | 'sourcing';
};
export const ResponsiveContext = React.createContext<ResponsiveContextShape>({
  innerWidth: 0,
  outerWidth: 0,
  context: 'mobile',
});

function determineContext({
  innerWidth,
  outerWidth,
}: {
  innerWidth: number;
  outerWidth: number;
}): ResponsiveContextShape['context'] {
  if (innerWidth > breakpoints.mobile) {
    return 'desktop';
  } else if (innerWidth <= breakpoints.mobile) {
    if (outerWidth >= breakpoints.wide) {
      return 'sourcing';
    }
  }
  return 'mobile';
}

function generateResponsiveContextValue(): ResponsiveContextShape {
  return {
    innerWidth: window.innerWidth,
    outerWidth: window.outerWidth,
    context: determineContext(window),
  };
}

export function ResponsiveProvider({children}: {children: React.ReactNode}) {
  const [windowWidth, setWindowWidth] = React.useState(
    generateResponsiveContextValue,
  );

  React.useEffect(() => {
    // TODO (kyle): debounce this?
    const handler = () => {
      setWindowWidth(generateResponsiveContextValue);
    };

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return (
    <ResponsiveContext.Provider value={windowWidth}>
      {children}
    </ResponsiveContext.Provider>
  );
}

export function useWindowWidth() {
  return React.useContext(ResponsiveContext);
}
