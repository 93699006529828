import React from 'react';

import {BrandingSettings} from './types';

import ChatbotDefaultAvatar from './ChatbotDefaultAvatar';

import {ReactComponent as CloseIcon} from './images/sourcing-close-icon.svg';
import classify from './utils/classify';

import css from './WidgetButton.module.css';

const WidgetButton = ({
  opened,
  onClick,
  flowBrandSettings,
}: {
  opened: boolean;
  onClick?: (opened: boolean) => void;
  flowBrandSettings: BrandingSettings;
}) => {
  const [widgetOpen, setWidgetOpened] = React.useState<boolean>(
    opened ?? false,
  );
  React.useEffect(() => {
    setWidgetOpened(opened);
  }, [opened]);
  const toggleWidget = () =>
    setWidgetOpened(prev => {
      onClick && onClick(true);
      return !prev;
    });

  return (
    <button
      className={classify(css.widgetButton)}
      aria-label={opened ? 'Close chatbot' : 'Open chatbot'}
      onClick={toggleWidget}
    >
      {widgetOpen && <CloseIcon className={css.iconClose} />}
      {flowBrandSettings.chatbot_avatar ? (
        <img
          className={classify(css.chatbotAvatar, {[css.pulse]: !widgetOpen})}
          src={flowBrandSettings.chatbot_avatar}
        />
      ) : (
        <ChatbotDefaultAvatar
          color={flowBrandSettings.color || '#007FAF'}
          className={classify(css.chatbotAvatar, css.iconChatbot, {
            [css.pulse]: !widgetOpen,
            [css.opened]: widgetOpen,
          })}
        />
      )}
    </button>
  );
};

export default WidgetButton;
