// @flow

import * as React from 'react';

import classify from '../../utils/classify';

import Unstyled from './unstyled';

import css from './button.module.css';

type Props = {
  type?: 'default' | 'primary',
  icon?: React.Node,
  children?: React.Node,
  disabled?: boolean,
  selected?: boolean,
  style: {backgroundColor?: string, color?: string},
  onKeyPress?: (SyntheticKeyboardEvent<HTMLElement>) => mixed,
  onClick?: (SyntheticEvent<HTMLElement>) => mixed,
};

/**
 * our default Button class
 *
 * We use a div because `<button>` has a lot of style quirks that differ
 * between browsers
 *
 * Note that onClick does NOT take a MouseEvent and instead just a regular Event.
 * This is because the keyup event can trigger the click event, and we'd rather
 * not use the `.click()` method.
 */
function Button(
  {type, icon, disabled, selected, ...props}: Props,
  ref: React.Ref<'div'>,
) {
  return (
    <Unstyled
      className={classify(
        type === 'primary' ? css.buttonPrimary : css.buttonDefault,
        {
          [css.buttonIcon]: icon,
          [css.buttonDisabled]: disabled || selected,
        },
      )}
      style={props.style}
      ref={ref}
      disabled={disabled}
      {...props}
    >
      {icon && <div className={css.icon}>{icon}</div>}
      <span className={css.children}>{props.children}</span>
    </Unstyled>
  );
}

export default React.forwardRef<Props, HTMLDivElement>(Button);
