import * as React from 'react';

import {ButtonData, AccessoryButtonData, HandlePostMessage} from './types';

// @ts-ignore
import Button from './@spaced-out/components/button';
// @ts-ignore
import CheckboxButton from './@spaced-out/components/button/checkbox-button';

import uiCss from './styles/ui.module.css';
export type ButtonStyle = {backgroundColor?: string; color?: string};

export function ButtonGroup({
  buttons,
  buttonStyle,
  onPostMessage,
  selectedValues,
}: {
  buttons: AccessoryButtonData[];
  buttonStyle: ButtonStyle;
  onPostMessage: (text: string, payload: string) => unknown;
  selectedValues?: Set<string>;
}) {
  const handleButtonClick = (button: AccessoryButtonData) => {
    onPostMessage(button.title, button.payload);
  };

  const Component = selectedValues ? CheckboxButton : Button;

  return (
    <div className={uiCss.buttonList}>
      {buttons.map(button => (
        <Component
          key={button.payload}
          style={buttonStyle}
          onClick={() => handleButtonClick(button)}
          selected={selectedValues?.has(button.payload)}
        >
          {button.title}
        </Component>
      ))}
    </div>
  );
}
