import * as React from 'react';
import {HandlePostMessage} from './types';
import Unstyled from './@spaced-out/components/button/unstyled';
import css from './Feedback.module.css';

export function FeedbackAccessory({onSubmit}: {onSubmit: HandlePostMessage}) {
  return (
    <div className={css.list}>
      {feedbackValues.map(({value, label}, index) => (
        <Unstyled
          className={`${css.button} ${css.flexButton}`}
          key={value}
          onClick={() => onSubmit(label, value)}
          style={{
            backgroundColor: css['color' + index],
          }}
        >
          <span className={css.title}>
            {index + 1} {label}
          </span>
        </Unstyled>
      ))}
    </div>
  );
}

const feedbackValues = [
  {value: '1: Very Bad', label: 'Very Bad'},
  {value: '2: Bad', label: 'Bad'},
  {value: '3: Ok', label: 'Ok'},
  {value: '4: Good', label: 'Good'},
  {value: '5: Very Good', label: 'Very Good'},
  //'6: No feedback',
];
