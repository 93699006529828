// @flow

import isPlainObject from 'lodash/isPlainObject';
import map from 'lodash/map';


function classify(
  ...args: Array<{[className: string]: mixed} | string | null | void>
): string {
  return args
    .map(arg => {
      if (isPlainObject(arg)) {
        arg = map(arg, (test, name: string) => (test ? name : null));
      }
      return arg;
    })
    .reduce((array, current) => {
      if (Array.isArray(current)) {
        array.push(...current);
      } else {
        array.push(current);
      }
      return array;
    }, [])
    .filter(val => val)
    .join(' ');
}

export default classify;
