import React from 'react';
import last from 'lodash/last';
import findLastIndex from 'lodash/findLastIndex';
import omitBy from 'lodash/omitBy';

import {
  ApiConversation,
  ApiConversationStart,
  Conversation,
  ChatbotDispatch,
  ChatbotState,
  ChatId,
  BrandingSettings,
  InboundDisplayMode,
  SenseAgencyConfig,
  ApiMessage,
  HandlePostMessage,
} from './types';

import api, {senseApi} from './utils/bot-api';
import {ApiError} from './utils/api';

import {faqApi} from './utils/faq-api';
import plural from './utils/plural';
import {stringify, parse} from './utils/hot-parser';
import playChime, {playChimeAndDispatch} from './utils/audio';
import {reduceChatbot, initialState} from './reducer';
import Loading, {ErrorPage, NotFound} from './Loading';
import Header from './Header';
import Chatbot from './Chatbot';
import {CloseChat} from './EndChat';
import {ResponsiveProvider, useWindowWidth} from './lib/Responsive';
import {BrandingContext, useBranding} from './lib/Branding';
import {ChatbotContext, useChatbotState} from './lib/ChatbotState';
import * as breakpoints from './breakpoints';
import {useThunkReducer} from './hooks/useThunkReducer';
import {useResourceApi, useApi} from './hooks/useApi';

import {useLATWS} from './utils/lat-ws';

import {ReactComponent as SenseLogo} from './images/sense-logo.svg';

import appCss from './App.module.css';
import DocumentHead from './DocumentHead';
import Unstyled from './@spaced-out/components/button/unstyled';

const App = ({
  chatId,
  isSms,
  isPreview = false,
  handleLogoClick,
  flowBrandSettings,
  handleChangeDisplayMode,
  inboundDisplayMode,
  isMobile,
  side = 'right',
  error,
  customParams,
}: {
  chatId: ChatId;
  isSms: boolean;
  isPreview?: boolean;
  handleLogoClick?: () => void;
  handleChangeDisplayMode?: (displayMode: InboundDisplayMode) => void;
  inboundDisplayMode?: InboundDisplayMode;
  flowBrandSettings?: BrandingSettings;
  isMobile?: boolean;
  side?: 'left' | 'right';
  error?: ApiError;
  customParams?: {[key: string]: string};
}) => {
  if (error) {
    return (
      <div className={appCss.body}>
        <ErrorPage error={error} />
      </div>
    );
  }

  const maximizeInboundDisplay = () => {
    if (handleChangeDisplayMode && inboundDisplayMode !== 'max') {
      handleChangeDisplayMode('max');
    }
  };
  return (
    <ResponsiveProvider>
      <div className={appCss.body}>
        {chatId.value ? (
          <BaseView
            chatId={chatId}
            isSms={isSms}
            isPreview={isPreview}
            handleLogoClick={handleLogoClick}
            flowBrandSettings={flowBrandSettings}
            handleChangeDisplayMode={handleChangeDisplayMode}
            inboundDisplayMode={inboundDisplayMode}
            maximizeInboundDisplay={maximizeInboundDisplay}
            isMobile={isMobile}
            side={side}
            customParams={customParams}
          />
        ) : (
          <NotFound />
        )}
      </div>
    </ResponsiveProvider>
  );
};

export default App;

let defaultState: ChatbotState;
try {
  const isDev = process.env.NODE_ENV === 'development';
  const hasExistingLocalState = !!localStorage.chatbotState;

  defaultState = isDev
    ? hasExistingLocalState
      ? parse(localStorage.chatbotState)
      : initialState
    : initialState;
} catch (err) {
  console.log(err);
  defaultState = initialState;
}

function BaseView({
  chatId,
  isSms,
  isPreview,
  handleLogoClick,
  flowBrandSettings,
  handleChangeDisplayMode,
  inboundDisplayMode,
  maximizeInboundDisplay,
  isMobile,
  side,
  customParams,
}: {
  chatId: ChatId;
  isSms: boolean;
  isPreview: boolean;
  handleLogoClick?: () => void;
  flowBrandSettings?: BrandingSettings;
  handleChangeDisplayMode?: (displayMode: InboundDisplayMode) => void;
  inboundDisplayMode?: InboundDisplayMode;
  maximizeInboundDisplay: () => void;
  isMobile?: boolean;
  side: 'left' | 'right';
  customParams?: {[key: string]: string};
}) {
  const [state, dispatch] = useThunkReducer(reduceChatbot, {
    ...defaultState,
    chatId,
    isSms,
    muteChime: false,
  });

  const sock = useLATWS(
    dispatch,
    state.sessionId,
    ['lat_wait', 'lat'].includes(state.mode),
    state.webSocketUrl,
  );

  const {conversation, error, muteChime} = state;

  const [branding, setBranding] = React.useState<BrandingSettings>(
    flowBrandSettings ?? {},
  );
  let shouldPlayChime = Boolean(branding.chatbot_audio_enabled) && !muteChime;

  const flowFaqEnabled = conversation && conversation.flow.show_top_faqs;

  if (process.env.NODE_ENV === 'development') {
    // the above conditional is basically a pragma
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      try {
        localStorage.chatbotState = stringify(state);
      } catch (err) {
        // looks like localStorage is disabled
        console.error(err);
      }
    }, [state]);
  }

  const releaseFlagsResource = useResourceApi<{[flag: string]: boolean}>(
    '/product-flags/release-flags',
    {api: senseApi},
    [],
  );

  const agencyConfigFlagsResource = useApi<SenseAgencyConfig>(
    '/agency/config',
    {
      api: senseApi,
      handleResponse: payload => {
        dispatch({type: 'receive_agency_config', payload});
      },
      handleError: err => {
        console.warn(err);
      },
    },
    [],
  );

  // NOTE (kyle): it's not worth it to show an error here since the
  // info we get from this api is not essential to completing a
  // conversation.
  const chatbotApiV2 = Boolean(releaseFlagsResource.result?.chatbot_api_v2);
  const chatbotFaqEnabled = Boolean(releaseFlagsResource.result?.chatbot_faq);

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      //@ts-ignore
      window._dispatch = dispatch;
      //@ts-ignore
      window._start = (conv: ApiConversationStart) =>
        playChimeAndDispatch(shouldPlayChime, () =>
          dispatch({type: 'start_conversation', payload: conv}),
        );
      //@ts-ignore
      window._receive = (conv: ApiConversation) =>
        playChimeAndDispatch(shouldPlayChime, () =>
          dispatch({type: 'receive_conversation', payload: conv}),
        );
      // @ts-ignore
      window._bootstrap = () => {
        // @ts-ignore
        dispatch({type: 'start_conversation', payload: waitStartStub});
      };
      // @ts-ignore
      window._receiveSingle = (message: ApiMessage) =>
        playChimeAndDispatch(shouldPlayChime, () => {
          const payload: ApiConversation = {messages: [message]};
          dispatch({type: 'receive_conversation', payload});
        });

      // @ts-ignore
      window._reset = () => {
        delete localStorage.currentId;
        delete localStorage.chatbotState;
        window.location.reload();
      };
    }

    if (!conversation && !releaseFlagsResource.isLoading) {
      let request;
      // NOTE (kyle): this should only happen once.
      api.setBasePath('/api/nlu');

      const params: {
        channel: 'sms' | 'web';
        code?: string;
        flow_id?: string;
        preview: boolean;
        replacements?: {[key: string]: string};
      } = {
        channel: isSms ? 'sms' : 'web',
        preview: isPreview,
        // for now, replacements passthrough but more data could be
        // included that aren't specifically passthrough
        replacements: customParams,
      };

      if (chatId.type === 'chat_code') {
        params.code = chatId.value;
      } else {
        params.flow_id = chatId.value;
      }
      request = api.post('/start', params);

      request.then(
        (conversation: ApiConversationStart) => {
          setBranding(conversation.branding_settings);
          // b/c this effect runs _once_ we re-init shouldPlayChime here
          // it will get updated outside the effect anyway once the component rerenders
          // but it will be incorrect for the playChimeAndDispatch call
          shouldPlayChime =
            Boolean(conversation.branding_settings.chatbot_audio_enabled) &&
            !muteChime;

          // set defaults
          window?.gtag?.('set', {agency_id: conversation.agency_id});

          window?.gtag?.('event', 'start_conversation', {shouldPlayChime});

          dispatch({
            type: 'receive_session_id',
            payload: conversation.conversation_id,
          });
          playChimeAndDispatch(shouldPlayChime, () =>
            dispatch({
              type: 'start_conversation',
              payload: conversation,
            }),
          );
          try {
            faqApi.get('', {agency_id: conversation.agency_id}).then(
              data => {
                dispatch({type: 'receive_faqs', payload: data});
              },
              err => {
                console.warn('could not fetch faqs', err);
              },
            );
          } catch (err) {}
        },
        error => {
          dispatch({type: 'receive_error', payload: error});
        },
      );
    }
  }, [
    conversation,
    chatId,
    dispatch,
    isSms,
    releaseFlagsResource.isLoading,
    chatbotApiV2,
    isPreview,
  ]);

  const showMinView =
    (inboundDisplayMode === 'min' || inboundDisplayMode === 'none') &&
    conversation;

  React.useEffect(() => {
    if (shouldPlayChime) {
      playChime();
    }
  }, [shouldPlayChime]);

  React.useEffect(() => {
    const brandingDefaults = {
      color: '#007FAF',
      button_color: '#007FAF',
      chatbot_bot_name: 'Reva',
      chatbot_bot_tagline: 'Recruiting Assistant',
      chatbot_bubble_color: '#F7F7F7',
      chatbot_font_color: '#000000',
      chatbot_initial_greeting: '👋  Hello! I am here to help.',
      chatbot_audio_enabled: false, //due to us filtering out "null"s from the api, this needs to be set to false. If there is a true value being sent from branding settings, this will be overwritten
    };

    let brandingSettings;
    if (flowBrandSettings) {
      // arriving from a sourcing bot that supplied a branding on mount
      brandingSettings = flowBrandSettings;
    } else if (conversation?.branding_settings) {
      // a web bot whose conversation included branding settings
      brandingSettings = conversation.branding_settings;
    }

    if (brandingSettings != null) {
      const filteredSettings = omitBy(brandingSettings, val => val === null);
      const mergedBranding = {...brandingDefaults, ...filteredSettings};
      setBranding(mergedBranding);
      // this renames the chatbot from reva after we pull the brand settings
      // b/c we can't otherwise do this without doing some ssr CHAT-2760
      document.title = mergedBranding.chatbot_bot_name;
    }
  }, [conversation, flowBrandSettings]);

  if (inboundDisplayMode === 'min') {
    return (
      <BaseMinView
        flowBrandSettings={branding}
        conversation={conversation}
        side={side}
        handleLogoClick={handleLogoClick}
      />
    );
  }

  return error ? (
    <ErrorPage error={error} />
  ) : !conversation ? (
    <Loading
      color={flowBrandSettings ? flowBrandSettings.button_color : undefined}
    />
  ) : (
    <ChatbotContext.Provider value={{state, dispatch}}>
      <BrandingContext.Provider value={branding}>
        <ConversationView
          chatId={chatId}
          conversation={conversation}
          state={state}
          dispatch={dispatch}
          chatbotApiV2={chatbotApiV2}
          handleLogoClick={handleLogoClick}
          isPreview={isPreview}
          inboundDisplayMode={inboundDisplayMode}
          maximizeInboundDisplay={maximizeInboundDisplay}
          flowBrandSettings={flowBrandSettings}
          isMobile={isMobile}
          chatbotFaqEnabled={Boolean(chatbotFaqEnabled && flowFaqEnabled)}
        />
      </BrandingContext.Provider>
    </ChatbotContext.Provider>
  );
}

function BaseMinView({
  flowBrandSettings,
  conversation,
  side,
  handleLogoClick,
}: {
  flowBrandSettings?: BrandingSettings;
  conversation: Conversation | null;
  side: 'left' | 'right';
  handleLogoClick?: () => void;
}) {
  const lastOutgoingIndex = conversation
    ? findLastIndex(
        conversation.messages,
        ({direction}) => direction === 'outgoing',
      )
    : -1;

  let unansweredMessageCount = 0;
  if (lastOutgoingIndex >= 0) {
    if (lastOutgoingIndex + 1 !== conversation?.messages?.length) {
      unansweredMessageCount = conversation
        ? conversation.messages.length - (lastOutgoingIndex + 1)
        : 0;
    }
  } else {
    unansweredMessageCount = conversation ? conversation.messages.length : 0;
  }

  // @ts-ignore findLast is an array method, but not in ts lib
  const lastIncoming = (conversation?.messages ?? []).findLast(
    ({direction}: {direction: 'incoming' | 'outgoing'}) =>
      direction === 'incoming',
  );
  const conversationOver =
    lastIncoming && lastIncoming.type === 'conversation-end';

  if (
    conversationOver ||
    (lastOutgoingIndex !== -1 && unansweredMessageCount === 0)
  ) {
    return <div></div>;
  }

  const formattedGreeting = flowBrandSettings?.chatbot_initial_greeting
    ? flowBrandSettings.chatbot_initial_greeting.replaceAll(
        ' ',
        `\u00A0`, //nonbreaking space
      )
    : '';

  const minViewMessage =
    lastOutgoingIndex !== -1
      ? `${unansweredMessageCount} new ${plural(
          unansweredMessageCount,
          'message',
          'messages',
        )}!`
      : formattedGreeting;

  return (
    <Unstyled
      aria-live="polite"
      className={
        side === 'left'
          ? appCss.inboundMinContainerLeft
          : appCss.inboundMinContainerRight
      }
      style={
        flowBrandSettings && {
          color: flowBrandSettings.chatbot_font_color,
          border: `2px solid ${flowBrandSettings.color}`,
        }
      }
      onClick={handleLogoClick}
    >
      <p>{minViewMessage}</p>
    </Unstyled>
  );
}

function ConversationView({
  chatId,
  conversation,
  state,
  dispatch,
  chatbotApiV2,
  handleLogoClick,
  isPreview,
  inboundDisplayMode,
  maximizeInboundDisplay,
  flowBrandSettings,
  isMobile,
  chatbotFaqEnabled,
}: {
  chatId: ChatId;
  conversation: Conversation;
  state: ChatbotState;
  dispatch: ChatbotDispatch;
  chatbotApiV2: boolean;
  isPreview: boolean;
  handleLogoClick?: () => void;
  inboundDisplayMode?: InboundDisplayMode;
  maximizeInboundDisplay: () => void;
  flowBrandSettings?: BrandingSettings;
  isMobile?: boolean;
  chatbotFaqEnabled?: boolean;
}) {
  const {innerWidth: windowWidth, context: botContext} = useWindowWidth();
  const {
    branding_settings: {logo, display_name, favicon},
  } = conversation;
  const [showCloseChat, setShowCloseChat] = React.useState(
    state.isDone && state.conversation && chatId.type === 'chat_code',
  );

  const handlePostMessage = React.useCallback(
    (text: string, payload?: string | string[]) => {
      dispatch({
        type: 'post_message',
        payload: text,
      });

      window?.gtag?.('event', 'sent_message');

      const body_and_json = state.isSms
        ? // sms message previews can't consume json
          {body: Array.isArray(payload) ? payload.join(',') : payload ?? text}
        : {
            body_json: Array.isArray(payload) ? payload : undefined,
            body: !Array.isArray(payload) ? payload ?? text : undefined,
          };

      const params: {
        session_id: string;
        body?: string;
        code?: string;
        flow_id?: string;
        body_json?: string[];
        preview: boolean;
      } = {
        ...body_and_json,
        session_id: state.sessionId,
        preview: isPreview,
      };

      if (chatId.type === 'chat_code') {
        params.code = chatId.value;
      } else {
        params.flow_id = chatId.value;
      }

      const request = api.post('/send', params);

      request
        .then((conversation: ApiConversation) =>
          playChimeAndDispatch(
            !state.muteChime &&
              Boolean(flowBrandSettings?.chatbot_audio_enabled),
            () => {
              if (conversation == null) {
                // pass
                // this only happens when we send a response to the LAT
                // in these cases we expect to wait for a reply message on the
                // socket and not from /send
                // TODO(marcos): verify chatbot 'mode' is LAT in this case
                // or check status code
              } else if (conversation.messages != null) {
                dispatch({type: 'receive_conversation', payload: conversation});
              }
            },
          ),
        )
        .catch(error => {
          dispatch({type: 'receive_conversation_error', payload: error});
        });
    },
    [
      dispatch,
      state.isSms,
      state.sessionId,
      state.muteChime,
      isPreview,
      chatId.type,
      chatId.value,
      flowBrandSettings,
    ],
  );

  React.useEffect(() => {
    if (state.isDone && state.conversation && chatId.type === 'chat_code') {
      // setTimeout(() => setShowCloseChat(true), 5000);
    }
  }, [chatId.type, state.conversation, state.isDone]);

  React.useEffect(() => {
    if (state.mode === 'lat_terminating') {
      handlePostMessage('', 'HAND_BACK::');
    }
  }, [state.mode]);

  let content;
  // don't show end chat if in preview mode
  content = (
    <div className={appCss.content}>
      <Chatbot
        chatId={chatId}
        state={state}
        dispatch={dispatch}
        chatbotApiV2={chatbotApiV2}
        isPreview={isPreview}
        mode={handleLogoClick ? 'bot' : undefined}
        branding_settings={conversation.branding_settings ?? {}}
        inboundDisplayMode={inboundDisplayMode}
        maximizeInboundDisplay={maximizeInboundDisplay}
        chatbotFaqEnabled={chatbotFaqEnabled}
        onPostMessage={handlePostMessage}
      />
    </div>
  );

  const lastMessage =
    Array.isArray(state?.conversation?.messages) &&
    last(state?.conversation?.messages); // ?. for typescript
  const inputDisabled =
    state.isDone ||
    !lastMessage ||
    lastMessage.direction !== 'incoming' ||
    lastMessage.type === 'scheduler';

  return (
    <>
      <DocumentHead>
        {favicon && <link rel="icon" href={favicon} />}
      </DocumentHead>

      <Header
        logo={logo}
        name={conversation.branding_settings?.chatbot_bot_name}
        description={conversation.branding_settings?.chatbot_bot_tagline}
        displayName={display_name}
        handleLogoClick={handleLogoClick}
        dispatch={dispatch}
        state={state}
        isMobile={isMobile}
        chatbotFaqEnabled={chatbotFaqEnabled}
        inputDisabled={inputDisabled}
        onPostMessage={handlePostMessage}
      />

      {botContext === 'desktop' ? (
        <>
          <div className={appCss.bg}>{content}</div>
          <div className={appCss.footer}>
            {!state.agencyConfig?.chatbot_white_label_frontend && (
              <>
                <span className={appCss.footerText}>powered by</span>
                <div className={appCss.footerLogo}>
                  <SenseLogo />
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        content
      )}
    </>
  );
}

const debugConversation = {
  first_name: 'Kyle',
  conversation_id: 'ie6sZu5TN0oMf4XtjN27Ua',
  flow: {
    show_top_faqs: false,
  },
  branding_settings: {
    chatbot_avatar:
      'https://s3-us-west-2.amazonaws.com/media.sense/media/tmp/2025ed4790f640259ff925c340c155e3.png',
    og_image:
      'https://s3-us-west-2.amazonaws.com/media.sense/media/tmp/a1b767d700e34f7b8b20bf896e30da3d.png',
    logo:
      'https://s3-us-west-2.amazonaws.com/media.sense/media/tmp/00428a62e7ca4c23b34d87903a071644.png',
    favicon:
      'https://s3-us-west-2.amazonaws.com/media.sense/media/tmp/6b3f6396b10a41638dd7f64b557ab6c5.png',
    display_name: 'SenseHQ',
    color: '#FFFFFA',
    button_color: '#007faf',
    chatbot_banner_message: {
      blocks: [
        {
          key: '8apnk',
          text:
            '[Privacy Policy](https://www.terrastaffinggroup.com/privacy-policy/) ',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [
            {
              offset: 0,
              length: 68,
              key: 0,
            },
          ],
          data: {},
        },
        {
          key: '6apnk',
          text:
            'More things about private policy but really this is just to test the second line thing and hope it shows properly yay ok we should be there now',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {
        '0': {
          type: 'HYPERLINK',
          mutability: 'IMMUTABLE',
          data: {
            label: 'Privacy Policy',
            url: 'https://www.terrastaffinggroup.com/privacy-policy/',
          },
        },
      },
    },
  },
  agency_id: '3384364471276985052',
};

// @ts-ignore
const waitStartStub = {
  messages: [
    {
      message_id: '1',
      type: 'plain-text',
      text:
        "Hello, Kyle! I'm Reva, your recruiting assistant. I\u2019m here to help Bonney Staffing gather some additional kylejwarren.com information for a job application",
      recipient_id: 'ie6sZu5TN0oMf4XtjN27U',
      metadata: {event_type: 'plain-text'},
    },

    // {
    //   message_id: '2',
    //   type: 'lat-wait-start',
    //   recipient_id: 'ie6sZu5TN0oMf4XtjN27U',
    //   text: 'joining queue...',
    //   metadata: {
    //     event_type: 'lat-wait-start',
    //     connectionMessage: 'Agent ALEX has joined the chat',
    //   },
    // },

    // // this comes from websocket
    // {
    //   message_id: '3',
    //   text: 'Agent Alex is joining your chat...',
    //   recipient_id: '2',
    //   type: 'lat-agent-join',
    //   metadata: {
    //     event_type: 'lat-agent-join',
    //     agent_id: '__AGENT', // could be anything
    //     agent_handle: 'Pizza Bot',
    //     avatar: 'https://cipro.generic.cx/pizza_72.png', // some gravatar or static url for now
    //   },
    // },

    // // comes from websocket
    // {
    //   message_id: '4',
    //   recipient_id: '1',
    //   text: 'Hi this is alex, are you looking to forklift',
    //   type: 'lat-agent-message',
    //   agent_id: '__AGENT',
    //   metadata: {
    //     event_type: 'lat-agent-message',
    //   },
    // },

    //  // when we hit this lat-agent-drop, we need to trigger
    //  // a request from the client to fetch the next nlu message
    //  // is this just an "advance" ping we hit nlu/send?
    // {
    //   message_id: '5',
    //   text: "Bye!",
    //   type: 'lat-agent-drop',
    //   recipient_id: '1',
    //   metadata: {
    //     event_type: 'lat-agent-drop',
    //     agent_id: '__AGENT',
    //   },
    // },

    // {
    //   message_id: '6',
    //   type: 'plain-text',
    //   text:
    //     "Good talk!",
    //   recipient_id: 'ie6sZu5TN0oMf4XtjN27U',
    //   metadata: {event_type: 'plain-text'},
    // }
  ],
  ...debugConversation,
};
