import * as React from 'react';

import classify from './classify';

/*
export default function makeClassNameComponent<T extends keyof JSX.IntrinsicElements>(
  baseClassName: string,
  ComponentType: T,
) {
  return function ClassNameComponent(props: React.ComponentPropsWithoutRef<T>) {
    return (
      <ComponentType
        {...props}
        className={classify(props.className, baseClassName)}
      />
    );
  };
}
 */

export default function makeClassNameComponent(
  baseClassName: string,
  ComponentType: keyof React.ReactHTML = 'div',
) {
  return function ClassNameComponent(
    props: React.ComponentPropsWithoutRef<typeof ComponentType>,
  ): React.ReactElement {
    return (
      // @ts-ignore this HOC is beyond my ability to typ
      <ComponentType
        {...props}
        className={classify(props.className, baseClassName)}
      />
    );
  };
}
