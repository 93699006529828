import * as React from 'react';
import classnames from 'classnames';

import {ChatbotState, WritebackData} from './types';

import api from './utils/bot-api';

// @ts-ignore
import Button from './@spaced-out/components/button';

import {ReactComponent as CheckmarkIcon} from './images/checkmark.svg';

import tCss from './EndChat.module.css';

export default function EndChat({
  sessionId,
}: {
  sessionId: ChatbotState['sessionId'];
}) {
  const [rows, setRows] = React.useState<Array<WritebackData>>([]);

  const isDevelopment =
    window.location.search === '?testing' ||
    process.env.NODE_ENV === 'development';

  React.useEffect(() => {
    if (isDevelopment) {
      api
        .get('/writeback', {
          user_id: sessionId,
        })
        .then(
          (writebackInfo: {
            data: Array<WritebackData>;
            chat: [{user_type: string; text: string}];
          }) => {
            if (writebackInfo) {
              const {data} = writebackInfo;
              setRows(
                data
                  .filter(
                    (row: WritebackData) => row.ats_field && row.field_name,
                  )
                  .map((row: WritebackData) => ({
                    ...row,
                    field_name: fieldNames[row.field_name] ?? row.field_name,
                  })),
              );
            }
          },
        )
        .catch(error => {
          // TODO (iris): CloseChat will be rendered but still, handle error
          console.error(error);
        });
    }
  }, [isDevelopment, sessionId]);

  if (rows.length) {
    return <WritebackFieldTable rows={rows} />;
  } else {
    return <CloseChat />;
  }
}

// NOTE (iris): this should not be shown to external users
function WritebackFieldTable({rows}: {rows: Array<WritebackData>}) {
  const titles: string[] = ['ATS Field', 'Updated Data'];

  return (
    <div className={tCss.container}>
      <span className={tCss.tableTitle}>ATS Data Writebacks</span>
      <div className={tCss.tableContainer}>
        <div className={tCss.fieldTable}>
          <div className={tCss.column}>
            {titles.map(title => (
              <div
                key={title}
                className={classnames(tCss.rowValue, tCss.title)}
              >
                {title}
              </div>
            ))}
          </div>
          {rows.map((row, i) => (
            <div key={i} className={tCss.column}>
              <div className={tCss.rowValue}>{row.field_name}</div>
              <div className={classnames(tCss.rowValue, tCss.fieldValue)}>
                {row.field_value}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const fieldNames: {[fieldName: string]: string} = {
  'address.zip': 'Zipcode',
  mobile: 'Phone Number',
  employmentType: 'Contract/Full-Time',
  companyName: 'Company Name',
  workAuthorized: 'Eligible to work in the U.S.?',
  "'title'": 'Title',
};

export function CloseChat({copy}: {copy?: string}) {
  return (
    <div className={tCss.chatCompleteContainer}>
      <div className={tCss.chatComplete}>
        <div className={tCss.title}>
          <CheckmarkIcon className={tCss.checkmarkIcon} />
          Completed!
        </div>
        <div className={tCss.chatCompleteText}>
          {copy ||
            'Thank you for providing the information. We appreciate you taking the time. Have a good day.'}
        </div>
        <Button
          type="primary"
          onClick={
            window.location.search === '?testing'
              ? () => window.location.reload()
              : () => window.close()
          }
        >
          Close Window
        </Button>
      </div>
    </div>
  );
}
